<template>
  <section id="history" class="m-major">
    <p class="m-major-title">大事记</p>
    <div class="m-major-history">
      <div class="m-major-history-public m-major-history-carousel">
        <div class="slick-list m-major-history-carousel-list">
          <div class="slick-list-track" ref="trackRef">
            <div :data-index="i" :class="[currentIndex === i ?  'slick-slide slick-slide-active' : 'slick-slide']" aria-hidden="true" v-for="(item, i) in data" :key="i">
              <div>
                <div class="stone-wrapper" style="width:100%;display:inline-block">
                  <div class="stone-contents">
                    <div class="stone-contents-img"></div>
                    <div class="words">
                      <p class="time">{{item.time}}</p>
                      <p class="desc">{{item.cont}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-major-history-public m-major-history-carouse2">
        <div class="slick-list">
          <div class="slick-list-track" ref="slickTrackRef">
            <div style="outline: none; width: 138.555px;" @click="handleItem(i)" :data-index="i" class="slick-slide1 slick-active" aria-hidden="false"  v-for="(item, i) in data" :key="i">
              <div>
                <li style="width:100%;display:inline-block">
                  <div :class="[ currentIndex === i ? 'slick-list-track-img slick-list-track-active' : 'slick-list-track-img']" style="background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABdCAMAAAAfbfHPAAAAWlBMVEUAAAA5XKo5XKpAV6M4XKc5XKs4WKc5W6s4Wqk4W6g5XKs4XKk5W6k4Wqk1Wqk5XKs5XKr////a3u5RcLXu8PjGzueUpNF+k8jh5vJherq7xeKxvN2jsNdvhsGVCoabAAAAEHRSTlMA3+8QQL8gn2Bwz4CQUDCv5OsCXwAAAk9JREFUaN612OmSqjAQBeDOnhCQVnRc5/1f8wLeqlhjEbL1+anlV+0xRAikRQ/WGzMqpYzxVgpoFCF7xfFPuPKyXnYB/spodQUtZzkeI0uH7jAhzBbYbh2ahJcMM8KGnEZGzIzRqfYQaaSyG9FjUfoEW3dYGLZbzYEhUumSY0X4IWZbrIyNLBOsjtzsO3TSvBnNMKTxryqCXacL+I7BRjHftsNmcV+F83Y4/1u7woZRG1cPQTGatcW5gBCPjeM/BkdEstENNo//bJxsdNveDgumdPDz5ee+t9blxtvTHn6cc7tct3b26M95Oj+uUxRfc4reDbAtfPno+VGK88U+YASfU4CHXhwV7mZcUeFqxjkVzpfKqXDUMNDhFjwd7mHMx6fr8zYl4AZUJn593JZXU3AFXSoeXk3FGTBKHFPwn3sJjkn4dDzOe/dEha+5UOKnbJzT4RwYHc6go8MVjJR4T4f34OhwB5IOlyDocAHA8vDXORXvAMDk4YjTvKWn4GbGbR7+zh338WHGRQmOCbiOPSWe1o32txRX0Wfn1/sv4j9+e/5OWbhdccFxJ9PzGuBPPHb7r+uenl/v7xI/XpBIEEnx4B+uILrRLQDZ6AyAbvQBgGx00/6gKETTHHGFUwWiYhgQnKBFji0twRIP8QSFh5j2qzBEdE32FDK9E0CgBzuij1V9R+zaNdNH2MqNgLsIWnmwyw6Qlr6gknfdFMMrCTmxLLdtEp77pRESXjkBhZEm6vNeQlVk321c6kGuiZB+7HhgeWfcIKBlhJZLDjqd/QdNWqIbJIlgjAAAAABJRU5ErkJggg==)">
                    <div style="transform: scale(0.5);color: #fff;transform-origin: 6px 10px;">
                      <span style="display: inline-block;">{{item.time.slice(0, 4)}}</span>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { toRefs, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
export default {
  setup () {
    const trackRef = ref(null)
    const slickTrackRef = ref(null)
    const currentIndex = ref(0)
    const totalWidth = ref(0)
    const target = {
      data: [
        {
          time: '2011年8月',
          imgUrl: '',
          cont: '成都云隆科技有限公司注册成立'
        },
        {
          time: '2011年9月',
          imgUrl: '',
          cont: '依托工程行业，成立绿色装备部、软件技术部'
        },
        {
          time: '2012年2月',
          imgUrl: '',
          cont: '开始系统集成建设项目'
        },
        {
          time: '2012年3月',
          imgUrl: '',
          cont: '取得测绘丙级资质'
        },
        {
          time: '2012年8月',
          imgUrl: '',
          cont: '取得《计算机信息系统集成企业》四级资质'
        },
        {
          time: '2013年5月',
          imgUrl: '',
          cont: '开始工程应用软件工具类研发'
        },
        {
          time: '2013年8月',
          imgUrl: '',
          cont: '获得《建筑智能化工程专业承包三级 》资质'
        },
        {
          time: '2013年12月',
          imgUrl: '',
          cont: '取得高新技术企业认证资格'
        },
        {
          time: '2014年9月',
          imgUrl: '',
          cont: '获得《计算机信息系统集成企业三级》资质'
        }, {
          time: '2014年12月',
          imgUrl: '',
          cont: '列入四川省创新型培育企业'
        },
        {
          time: '2016年6月',
          imgUrl: '',
          cont: '获得《建筑智能化工程专业承包二级》资质'
        },
        {
          time: '2017年1月',
          imgUrl: '',
          cont: '开始创新技术应用研究，对接物联网、云计算、大数据、AI'
        },
        {
          time: '2017年10月',
          imgUrl: '',
          cont: '获得2015-2016年度武侯区科学技术进步奖三等奖'
        },
        {
          time: '2018年3月',
          imgUrl: '',
          cont: '获得2015-入库四川省2018年第三批科技型中小企业'
        },
        {
          time: '2018年5月',
          imgUrl: '',
          cont: '获得2017年度四川省科学技术进步奖三等奖'
        },
        {
          time: '2018年9月',
          imgUrl: '',
          cont: '获得《电子与建筑智能化工程专业承包一级》资质'
        },
        {
          time: '2018年10月',
          imgUrl: '',
          cont: '开始智慧建造平台的开发和应用工作'
        },
        {
          time: '2019年8月',
          imgUrl: '',
          cont: '确定系统集成、研发双主业模式'
        },
        {
          time: '2019年10月',
          imgUrl: '',
          cont: '获得高新技术企业证书'
        },
        {
          time: '2020年2月',
          imgUrl: '',
          cont: '着手盾构大脑、物资平台、设备平台研发'
        },
        {
          time: '2021年5月',
          imgUrl: '',
          cont: '进军山东市场，成立山东办事处'
        }
      ]
    }

    onMounted(() => {
      trackRef.value.style.width = `${target.data.length * trackRef.value.children[0].offsetWidth + 70}px`
      trackRef.value.style.transform = 'translate3d(0, 0 ,0)'
      slickTrackRef.value.style.width = `${target.data.length * slickTrackRef.value.children[0].offsetWidth}px`
      slickTrackRef.value.style.transform = `translate3d(${slickTrackRef.value.parentNode.clientWidth / 2 - slickTrackRef.value.children[0].offsetWidth / 2}px, 0, 0)`
      totalWidth.value = slickTrackRef.value.parentNode.clientWidth / 2
    })

    const handleItem = (i) => {
      if (i !== currentIndex.value) {
        currentIndex.value = i
        trackRef.value.style.transform = `translate3d(-${i * (trackRef.value.children[0].offsetWidth)}px ,0 , 0)`
        slickTrackRef.value.style.transform = `translate3d(${totalWidth.value - (i * slickTrackRef.value.children[0].offsetWidth + 70)}px, 0, 0)`
      }
    }

    return { ...toRefs(target), trackRef, handleItem, slickTrackRef, currentIndex }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/variable.scss';
.slick-list {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  &-track {
    height: 100%;
    opacity: 1;
    transition: all .2s ease;
    &-img {
      width: .32rem;
      height: .32rem;
      margin: auto;
      margin-top: -.15rem;
      border-radius: 51%;
      z-index: 1;
      transition: transform .15s ease;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: top;
    }
    &-active {
      transform: scale(1.75);
    }
  }
}

.slick-slide {
  outline:  none;
  width:  6.8rem;
  float: left;
  height: 100%;
  opacity: 0;
  transition: opacity .4s ease;
  > div {
    width: unset !important;
    height: 100%;
  }
}

.slick-slide-active {
  opacity: 1;
}

.slick-slide1 {
  height: 1rem;
  transition: all .2s ease;
  float: left;
  // transition: width .2s ease;
  > div {
    width: unset !important;
    height: 100%;
  }
  li {
    text-align: center;
    overflow: visible;
    height: .02rem;
    background-color: #333;
  }
}

.stone-wrapper {
  position: relative;
  height: 2.49rem;
  overflow: visible;
  width: 100%;
  display: inline-block;
}

.stone-contents {
  @include flex-center(space-between);
  width: 99%;
  height: 2.32rem;
  border-radius: .22rem;
  background-color:$color-background;
  box-shadow: 0 .04rem .05rem rgb(0 0 0 / 15%);
  overflow: hidden;
  &-img {
    background: #3c8cff url('/img/logo1.png') no-repeat center center;
    width: 15vw;
    height: 100%
  }
  > img {
    width: 3.14rem;
    height: 100%;
    object-fit: cover;
  }
  > .words {
      flex: 1;
      margin: .23rem .26rem 0 .45rem;
      text-align: left;
    > .time {
      font-family: "Gilroy-Bold";
      font-weight: 500;
      font-size: .3rem;
      line-height: .37rem;
    }
    > .desc {
      margin-top: .1rem;
      font-size: .18rem;
    }
  }
}

.m-major {
  position: relative;
  width: 100%;
  padding: 0 9vw;
  margin: 11vw auto 0;
  box-sizing: border-box;
  &-title {
    font-family: "Gilroy-Bold",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
    font-weight: 700;
    font-size: .48rem;
    line-height: .56rem;
  }
  &-history {
    &-public {
      position: relative;
      user-select: none;
    }
    &-carousel {
      margin: auto;
      margin-top: .57rem;
      text-align: center;
      overflow: visible;
      &-list {
        width: 6.8rem;
        height: 2.49rem;
        margin: auto;
        overflow: visible;
        overflow: hidden;
      }
    }
    &-carouse2 {
      width: 84%;
      height: .7rem;
      margin: .4rem auto 0;
      padding-top: .15rem;
      overflow: hidden;
    }
  }
}
</style>
