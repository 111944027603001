<template>
    <div class="banner-about">
      <Banner :banners="banners"/>
      <About />
    </div>
    <Culture />
    <Major />
</template>

<script>
import config from '@/utils/request/config'
import Banner from './components/Banner'
import Culture from './components/Culture'
import Major from './components/Major'
import About from './components/About'
import { GetIndexData } from '@/api/WsIndexDatasApi'
import { reactive, toRefs } from 'vue'
const initEffect = () => {
  const target = reactive({
    banners: [],
    cate: [],
    classics: []
  })
  const init = async () => {
    const { data: { Data } } = await GetIndexData()
    Data.banners.forEach(item => {
      item.imgUrl = config.hostUrl + item.file_url
    })

    Object.keys(target).forEach(key => {
      target[key] = Data[key]
    })
  }
  init()
  return { target }
}
export default {
  setup () {
    const { target } = initEffect()
    return { ...toRefs(target) }
  },
  components: {
    Banner,
    Culture,
    Major,
    About
  }
}
</script>

<style lang="scss" scoped>
.banner-about {
  position: relative;
}
</style>
